import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CustomPreloadingStrategyService } from "./shared/services/custom-preloading-strategy.service";
import { SsoLoginComponent } from "./pages/cms/sso-login/sso-login.component";

let routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "sson",
    component: SsoLoginComponent,
  },
  {
    path: "not-found",
    loadChildren: () =>
      import("./pages/not-found/not-found.module").then(
        (m) => m.NotFoundModule
      ),
  },
  {
    path: ":tripCode",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "**",
    redirectTo: "not-found",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
			preloadingStrategy: CustomPreloadingStrategyService,
			scrollPositionRestoration: 'enabled'
		})
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
