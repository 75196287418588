import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { CommonFunction } from "../_helpers/common-function";

@Injectable({
  providedIn: "root",
})
export class PromoCodeService {
  constructor(
    private http: HttpClient,
    public route: ActivatedRoute,
    private commonFunction: CommonFunction
  ) {}

  validatePromoCode(data) {
    return this.http.post(
      `${environment.apiUrl}v1/tripfluencer/check-coupon-code-validity`,
      data,
      this.commonFunction.setMembershipHeaders()
    );
  }

  codeVerify(code) {
    return this.http.post(
      `${environment.apiUrl}v1/tripfluencer/check-coupon-code-availability`,
      code,
      this.commonFunction.setMembershipHeaders()
    );
  }

  emailverify(email) {
    return this.http.get(
      `${environment.apiUrl}v1/tripfluencer/check-availability-email?email=${email}`,
      this.commonFunction.setMembershipHeaders()
    );
  }

  userNameverify(userName) {
    return this.http.post(
      `${environment.apiUrl}v1/tripfluencer/check-tripfluencer-available`,
      userName,
      this.commonFunction.setMembershipHeaders()
    );
  }
}
