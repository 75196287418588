export enum Module {
  HOTEL = "Hotel",
  FLIGHT = "Flight",
  SUBSCRIPTION = "Subscription",
}

export enum ModuleLowerCase {
  HOTEL = "hotel",
  FLIGHT = "flight",
  SUBSCRIPTION = "subscription",
}

export enum InstallmentsLowerCase {
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  BIWEEKLY = "biweekly",
}

export enum ltPartners {
  ARAJET = "Arajet",
}

export enum LanguagesLocale {
  ENGLISH = "en",
  ESPANOL = "es",
  PORTUGUESE = "pt",
}

export enum WhiteLabel {
  LAY_TRIP = "Laytrip",
}

export enum ShareWebsiteLinkMedium {
  INSTAGRAM = "instagram",
  FACEBOOK = "facebook",
  WHATSAPP = "whatsapp",
}
