import { CalenderFirstDayConst } from "../constant/generic.const";
import * as moment from "moment";
import { getLoginUserInfo } from "./jwt.helper";
import { LanguagesLocale } from "../enum/home.enum";
import { ActiveLanguageParams } from "../interface/home.interface";

export const installmentType = {
  en: {
    weekly: "Weekly",
    biweekly: "Bi-Weekly",
    monthly: "Monthly",
  },
  es: {
    weekly: "Semanal",
    biweekly: "Quincenal",
    monthly: "Mensual",
  },
  pt: {
    weekly: "Semanal",
    biweekly: "Quinzenal",
    monthly: "Mensal",
  },
};

export const pgDefaultLanguage: ActiveLanguageParams = {
  id: 3,
  name: "Español",
  iso_1Code: "es",
  active: true,
};

export function pluralize(
  word: string,
  count: number,
  ignoreEn: boolean = false,
  plural = { en: "s", es: "s", pt: "s" }
): string {
  const userLang: string =
    JSON.parse(localStorage.getItem("_lang"))?.iso_1Code || "en";
  if (ignoreEn && userLang == "en") return word;
  if (count > 1) word += plural[userLang];
  return word;
}

// Author: xavier | 2021/7/29 | 2021/8/13
// Description: Temporary workaround to avoid calling Google's Translate API
export function translateAmenities(title: string) {
  const userLang: string =
    JSON.parse(localStorage.getItem("_lang"))?.iso_1Code || "en";
  if (userLang == "en") return title;

  for (let i: number = 0; i < amenitiesTranslations.length; i++) {
    if (amenitiesTranslations[i].en == title) {
      return amenitiesTranslations[i][userLang];
    }
  }
  return title;
}

const amenitiesTranslations: { en: string; es: string; pt: string }[] = [
  {
    en: "Airport Shuttle",
    es: "Transporte al Aeropuerto",
    pt: "Transporte do Aeroporto",
  },
  { en: "Business Center", es: "Centro de Negocios", pt: "Centro de negócios" },
  {
    en: "Free Internet Available",
    es: "Internet Gratis Disponible",
    pt: "Internet gratuita disponível",
  },
  {
    en: "Free Internet Access",
    es: "Acceso Gratuito a Internet",
    pt: "Acesso Gratuito à Internet",
  },
  {
    en: "Free Internet In Public Areas",
    es: "Internet Gratis en Áreas Públicas",
    pt: "Internet gratuita em áreas públicas",
  },
  { en: "Accessible", es: "Accesible", pt: "Acessível" },
  {
    en: "No Smoking Rooms/Facilities",
    es: "Habitaciones / Instalaciones para No Fumadores",
    pt: "Quartos / Instalações para Não Fumantes",
  },
  {
    en: "Pets Allowed",
    es: "Mascotas Permitidas",
    pt: "São permitidos animais",
  },
  { en: "Restaurant", es: "Restaurante", pt: "Restaurante" },
  { en: "Free Breakfast", es: "Desayuno Gratuito", pt: "Café da manhã grátis" },
  { en: "Fitness Center or Spa", es: "Gimnasio o Spa", pt: "Gimnasio o Spa" },
  { en: "Free Parking", es: "Parqueo Gratuito", pt: "Estacionamento grátis" },
  { en: "Swimming Pool", es: "Piscina", pt: "Piscina" },
  {
    en: "Free Airport Shuttle",
    es: "Transporte Gratuito al Aeropuerto",
    pt: "Transporte Gratuito do Aeroporto",
  },
  { en: "Casino", es: "Casino", pt: "Cassino" },
];

export const CalendarTranslations:any = {
  en: {
    firstDayOfWeek: 0,
    dayNames: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    today: "Today",
    clear: "Clear",
  },
  es: {
    firstDayOfWeek: 0,
    dayNames: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
    dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
    monthNames: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    monthNamesShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    today: "Hoy",
    clear: "Limpiar",
  },
  pt: {
    firstDayOfWeek: 0,
    dayNames: [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
    ],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    today: "Hoje",
    clear: "Limpiar",
  },
};

export const socialMedia: { id: number; name: string }[] = [
  { id: 1, name: "Facebook" },
  { id: 2, name: "LinkedIn" },
  { id: 3, name: "Twitter" },
  { id: 4, name: "Instagram" },
  { id: 5, name: "Custom" },
];

export enum travelPackage {
  YES = 1,
  NO = 2,
}

export enum userLanguages {
  en = "English",
  es = "Español",
  pt = "Português",
}

export const popularAmenities = {
  "air condition": { name: "Air conditioned", icon: "air_conditioned.svg" },
  parking: { name: "Parking", icon: "parking.svg" },
  spa: { name: "Spa", icon: "spa.svg" },
  gym: { name: "Gym", icon: "gym.svg" },
  "swimming pool": { name: "Swimming pool", icon: "swimming.svg" },
  restaurant: { name: "Restaurant", icon: "restaurant.svg" },
};

export const hotelReview = {
  9: "Superb 9+",
  8: "Very Good 8+",
  7: "Good 7+",
  6: "Pleasant 6+",
};

export function getFirstDayOfWeek(locale: string | null): number {
  if (locale) {
    // Set the moment.js locale to the provided locale code
    moment.locale(locale);

    // Determine the start of the week (0 for Sunday, 1 for Monday)
    const weekStart = moment().startOf("week").day();

    // Retrieve the user's preferred language setting from localStorage
    const momentLocale =
      localStorage.getItem("_lang") && JSON.parse(localStorage.getItem("_lang"))
        ? JSON.parse(localStorage.getItem("_lang"))?.iso_1Code // Extract the ISO language code
        : LanguagesLocale.ENGLISH; // Default to 'en' if not found in localStorage

    // Check if the retrieved language code is valid within the LanguagesLocale enum
    if (
      Object.values(LanguagesLocale).includes(momentLocale as LanguagesLocale)
    ) {
      // Set the moment.js locale based on the user's preferred language
      moment.locale(momentLocale);
    }

    // Return the appropriate constant based on the start of the week
    return weekStart === 0
      ? CalenderFirstDayConst.SUNDAY
      : CalenderFirstDayConst.MONDAY;
  } else {
    // Retrieve the browser's default locale
    const browserLocale = Intl.DateTimeFormat().resolvedOptions().locale;

    // Return Monday for 'en-GB' and Sunday for other locales
    return browserLocale.startsWith("en-GB")
      ? CalenderFirstDayConst.MONDAY
      : CalenderFirstDayConst.SUNDAY;
  }
}

export function getSellingPriceForHotel(cugRateOb, nonCugRateOb) {
  const userDetails = getLoginUserInfo();

  // Return non-CUG rate if user role is not defined (using optional chaining)
  if (!userDetails?.roleId) return nonCugRateOb;

  // Check for roles that allow returning CUG rates
  const allowedCugRoles = [5, 10, 11];

  // Return CUG rate if available and user role is allowed
  if (
    (cugRateOb && allowedCugRoles.includes(userDetails?.roleId)) ||
    (cugRateOb && !nonCugRateOb)
  ) {
    return cugRateOb;
  }
  return nonCugRateOb;

  // Return the available rate, prioritize CUG rate if available
}

export function getNonSellingPriceForHotel(cugRateOb, nonCugRateOb) {
  const userDetails = getLoginUserInfo();
  const allowedCugRoles = [5, 10, 11];
  const isCugRole = allowedCugRoles.includes(userDetails?.roleId);

  // If both rates are present, return based on user role
  if (cugRateOb && nonCugRateOb) {
    return isCugRole ? nonCugRateOb : cugRateOb;
  }

  // If only CUG rate is available, return empty object for both roles
  if (cugRateOb) {
    return cugRateOb;
  }

  // Return non-CUG rate if available, regardless of role
  return nonCugRateOb;
}

export function reformatInformation(text?: string): string {
  if (!text) return "";

  return text
    .replace(/^[^\w\d]+/g, "") // Skip everything until first letter or digit
    .replace(/([a-zA-Z]):/g, "$1: ") // Add a space after a letter followed by a colon
    .replace(/([^\d])\./g, "$1. ") // Add a space after a non digit character followed by a period
    .replace(/(\d)\.([a-zA-Z])/g, "$1. $2") // Add a space after a number followed by a period, followed by a letter
    .replace(/(^\w{1})|(\.\s+\w{1})/g, (match) => match.toUpperCase()) // Capitalize the first letter of a sentence
    .replace(/\. /g, ".<br>")
    .trim(); // Split paragraphs (using <br>) after a period followed by a space
}
