import { enGbLocale, LocaleData } from "ngx-bootstrap/chronos";

// A custom 'en-us' locale with Sunday as the first day
export const enUsCustomLocale: LocaleData = {
    ...enGbLocale, 
    week: {
        dow: 0, // Sunday as the first day of the week
        doy: 6, // The day of the year where the week starts (January 1st is considered the start of the year)
    },
};

export const CalenderLocalization = {
    UK: "en-gb",
    US: "en-us"
};

export const LanguageTranslationConst = {
    ENGLISH: "en",
    ESPANOL: "es",
    PORTUGUESE: "pt"
};

export const WebsiteConst = {
    LAYTRIP: "LT",
    PAGOVOY: "PG"
}

export const CalenderFirstDayConst = {
    SUNDAY: 0,
    MONDAY: 1
}