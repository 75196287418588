import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { CommonFunction } from "../_helpers/common-function";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  constructor(
    private http: HttpClient,
    private commonFunction: CommonFunction
  ) {}

  private cacheForArajetAvailability = new Map<
    string,
    { data: any; expiry: number }
  >();
  private cacheDurationForArajetAvailability = 10 * 60 * 1000; // 10 minutes in milliseconds

  private toString: any = new BehaviorSubject({});
  getToString = this.toString.asObservable();

  private fromDestinationInfo: any = new BehaviorSubject({});
  getLocationForHotelDeal = this.fromDestinationInfo.asObservable();

  private tabName = new BehaviorSubject([]);
  getActiveTabName = this.tabName.asObservable();

  private checkLogin = new BehaviorSubject({});
  checkLoginUser = this.checkLogin.asObservable();

  private changedLanguage = new BehaviorSubject({});
  getChangedLanguage = this.changedLanguage.asObservable();

  private loadingDeal = new BehaviorSubject(false);
  getLoadingDeal = this.loadingDeal.asObservable();

  private fromLocation = new BehaviorSubject(false);
  getFromLocation = this.fromLocation.asObservable();

  private userIpDetails = new BehaviorSubject(false);
  getUserIpDetails = this.userIpDetails.asObservable();

  private flightGroupChanged = new BehaviorSubject(false);
  getFlightGroupChanged = this.flightGroupChanged.asObservable();

  private userCity = new BehaviorSubject({});
  getUserCity = this.userCity.asObservable();

  private hotel = new BehaviorSubject({});
  getHotel = this.hotel.asObservable();


  setUserCity(userCity) {
    this.userCity.next(userCity);
  }

  setLoadingDeal(loadingDeal) {
    this.loadingDeal.next(loadingDeal);
  }

  setUserIpDetails(userIpDetails) {
    this.userIpDetails.next(userIpDetails);
  }

  setFromLocation(fromLocation) {
    this.fromLocation.next(fromLocation);
  }

  setToString(flightToCode) {
    this.toString.next(flightToCode);
  }

  setLocationForHotel(destinationInfo) {
    this.fromDestinationInfo.next(destinationInfo);
  }

  setActiveTab(tabName) {
    this.tabName.next(tabName);
  }
  
  setCheckLoginUser(data) {
    this.checkLogin.next(data);
  }

  setChangedLanguage(data) {
    this.changedLanguage.next(data);
  }

  setFlightGroupChanged(data) {
    this.flightGroupChanged.next(data);
  }

  setHotel(hotel) {
    this.hotel.next(hotel);
  }

  // Get Travel Deals For Home-Page
  getUserTravelPackage(data) {
    let headers = {
      currency: "USD",
      language: this.commonFunction.getSelectedLangCode(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      source_code: this.commonFunction.getDomainName() || "LT",
    };
    let ltLocation = JSON.parse(localStorage.getItem("lt_location"));
    return this.http
      .get(
        `${environment.apiUrl}v1/travel-package/user?city_name=${data.city_name}&country_name=${data.country_name}&country_code=${data.country_code}&lat=${ltLocation?.lat}&long=${ltLocation?.long}`,
        this.commonFunction.setHeaders(headers)
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Get Travel Deals For Home-Page
  getUserArajetTravelPackage(data) {
    let headers = {
      currency: "USD",
      language: this.commonFunction.getSelectedLangCode(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      source_code: this.commonFunction.getDomainName() || "LT",
    };
    let ltLocation = JSON.parse(localStorage.getItem("lt_location"));
    return this.http
      .get(
        `${environment.apiUrl}v1/travel-package/ar/user?city_name=${data.city_name}&country_name=${data.country_name}&country_code=${data.country_code}&lat=${ltLocation?.lat}&long=${ltLocation?.long}`,
        this.commonFunction.setHeaders(headers)
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Get Loading Skeleton Showing Deals
  getTravelLoadingDeals() {
    return this.http
      .get(
        `${environment.apiUrl}v1/travel-package/list-package`,
        this.commonFunction.setHeaders()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Convert Travel Deal Toggle
  removeToString(module) {
    if (module == "flight") {
      this.toString.next({});
    } else if (module == "hotel") {
      this.fromDestinationInfo.next({});
    }
  }

  // Create Tripfluencer
  createTripfluencer(data) {
    return this.http.post(
      `${environment.apiUrl}v1/tripfluencer/create-trip-fluencer`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Get Login User Details
  getLoginUser() {
    return this.http
      .get(
        `${environment.apiUrl}v1/subscription/check-login-user`,
        this.commonFunction.setHeaders()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Get Socila Media Images In Chunk
  getInstaPost(limit: number, pageNo: number) {
    return this.http
      .get(
        `${environment.apiUrl}v1/home-page/list-social-post?limit=${limit}&page_no=${pageNo}`,
        this.commonFunction.setHeaders()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Get All Airports By Code
  getAirports(data) {
    return this.http.post(
      `${environment.apiUrl}v1/home-page/list-airports`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Get Airports By City Name
  getUserAirport(data) {
    let payload = [JSON.parse(data)];
    return this.http.post(
      `${environment.apiUrl}v1/home-page/user-airport`,
      payload,
      this.commonFunction.setHeaders()
    );
  }

  // Get Arajet Available Dates for 1 year Calendar
  getArajetAvailableDates(endpoint: string) {
    const cached = this.cacheForArajetAvailability.get(endpoint);

    if (cached && cached.expiry > Date.now()) {
      return of(cached.data);
    }
    return this.http
      .get(
        `${environment.apiUrl}v1/${endpoint}`,
        this.commonFunction.setHeaders()
      )
      .pipe(
        tap((data) => {
          this.cacheForArajetAvailability.set(endpoint, {
            data,
            expiry: Date.now() + this.cacheDurationForArajetAvailability,
          });
        })
      );
  }

  // Get Airports By City Name
  getAirportsByCities(data) {
    return this.http.post(
      `${environment.apiUrl}v1/seo/search-airports-by-cities`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Get Hotels By City Name
  getHotelsByCities(data) {
    return this.http.post(
      `${environment.apiUrl}v1/seo/search-hotels-by-cities`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Get All Airports
  getAllAirports(data) {
    return this.http.post(
      `${environment.apiUrl}v1/home-page/list-all-airports`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  getIpInfo() {
    const apiUrl = `https://directory.cookieyes.com/api/v1/ip`;
    return this.http.get(apiUrl);
  }

  getGeoInfo(ip) {
    return this.http
      .get(
        `${environment.apiUrl}v1/home-page/get-user-details?ip=${ip}`,
        this.commonFunction.setHeaders()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  getLocaleByCountryCode(countryCode: string) {
    return this.http.get(`https://restcountries.com/v3.1/alpha/${countryCode}`)
      .pipe(catchError(this.commonFunction.handleError));
  }

}
