import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { WebsiteConst } from "../constant/generic.const";

@Injectable({
  providedIn: "root",
})
export class SeoService {
  constructor(private title: Title, private _meta: Meta) {}

  updateMeta(meta: any) {
    if (meta) {
      const envCode = environment.code === "PG" ? "Pagovoy" : "Laytrip";

      // Update meta tags (title and meta tags)
      if (meta.meta) {
        Object.entries(meta.meta).forEach(([key, value]: [string, string]) => {
          const content = value.replace("Laytrip", envCode);

          if (key === "title") {
            this.title.setTitle(content);
          } else {
            this._meta.updateTag({ name: key, content });
          }
        });
      }

      // Update social meta tags
      if (meta.social) {
        Object.entries(meta.social).forEach(
          ([key, value]: [string, string]) => {
            const content = value.replace("Laytrip", envCode);
            this._meta.updateTag({ property: key, content });
          }
        );
      }

      // Update Open Graph (OG) meta tags
      if (meta.og) {
        Object.entries(meta.og).forEach(([key, value]: [string, string]) => {
          const content = value.replace("Laytrip", envCode);
          this._meta.updateTag({ property: key, content });
        });
      }
    }
  }
}
